/* Use following Lambda Node JS function:

var AWS = require('aws-sdk');
var ses = new AWS.SES();
 
var RECEIVER = 'test@gmail.com';
var SENDER = 'test@gmail.com';

var response = {
 "isBase64Encoded": false,
 "headers": { 'Content-Type': 'application/json'},
 "statusCode": 200,
 "body": "{\"result\": \"Success.\"}"
 };

exports.handler = function (event, context) {
    console.log('Received event:', event);
    sendEmail(event, function (err, data) {
        context.done(err, null);
    });
};
 
function sendEmail (event, done) {
    var params = {
        Destination: {
            ToAddresses: [
                RECEIVER
            ]
        },
        Message: {
            Body: {
                Text: {
                    Data: 'name: ' + event.name + '\nphone: ' + event.phone + '\nemail: ' + event.email + '\norganisation: ' + event.organisation + '\ndesc: ' + event.comment,
                    Charset: 'UTF-8'
                }
            },
            Subject: {
                Data: 'Website Referral Form: ' + event.name,
                Charset: 'UTF-8'
            }
        },
        Source: SENDER
    };
    ses.sendEmail(params, done);
}
*/


import { useState, useEffect } from 'react';
import axios from 'axios';

const useForm = (callback, validate) => {

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [sendSuccess, setSendSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values));
    // axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    const listOfErrors = validate(values);
    if (Object.keys(listOfErrors).length === 0)
    {
      setIsSubmitting(true);
      setTimeout(() => {
        postMessage();
      }, 2000);  
    }
  };

  const postMessage = (event) => {
    axios.post("https://4k6u9j8osb.execute-api.ap-southeast-2.amazonaws.com/thinkatlas-production/contact-us", values)
    .then(function(response) {
      setSendSuccess(true);
      setValues(values.name = '');
    })
    .catch(function(error) {
      console.log(error);
    });
  };

  const handleChange = (event) => {
    // console.log(event.target.name);
    event.persist();
    setValues(values => ({ ...values, [event.target.name]: event.target.value }));
    // validate(event.target.value);
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    sendSuccess,
    isSubmitting,
  }
};

export default useForm;