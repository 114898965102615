import React from "react";

export default function ServiceSection() {
  return (
    <section id="ServiceSection" name="ServiceSection" className="services-four pt-115 rpt-95 pb-210 rpb-150">
      <div className="container">
        <div className="row justify-content-between align-items-center mb-40">
          <div className="col-lg-6 wow fadeInLeft delay-0-2s">
            <div className="section-title mb-35">
              <span className="sub-title">Support For Individuals And Businesses</span>
              <h2>About Us</h2>
            </div>
          </div>
          <div className="col-lg-5 wow fadeInRight delay-0-2s">
            <p>
            We're a software development company specialising in designing and developing custom software, end-to-end IT services,
            data engineering and infrastructure management. We engage with our clients to identify their business objectives and 
            deliver the right solution using the right technologies.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="service-item-four wow fadeInUp delay-0-2s">
              <div className="image">
                <img
                  src={
                    require(`../../assets/images/services/developing.jpg`)
                      .default
                  }
                  alt="Service"
                />
              </div>
              <div className="service-four-content">
                <div className="service-title-area">
                  <span className="category">Software</span>
                  <h3>
                    Software/Web Development
                  </h3>
                </div>
                <i className="flaticon flaticon-settings-1"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="service-item-four wow fadeInUp delay-0-4s">
              <div className="image">
                <img
                  src={
                    require(`../../assets/images/services/cloud.jpg`)
                      .default
                  }
                  alt="Service"
                />
              </div>
              <div className="service-four-content">
                <div className="service-title-area">
                  <span className="category">DevOps</span>
                  <h3>
                    Cloud Infrastructure Management
                  </h3>
                </div>
                <i className="flaticon flaticon-seo-1"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="service-item-four wow fadeInUp delay-0-6s">
              <div className="image">
                <img
                  src={
                    require(`../../assets/images/services/database.jpg`)
                      .default
                  }
                  alt="Service"
                />
              </div>
              <div className="service-four-content">
                <div className="service-title-area">
                  <span className="category">Data</span>
                  <h3>
                    Database Management/Design
                  </h3>
                </div>
                <i className="flaticon flaticon-web"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
