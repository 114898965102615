import React from "react";
import BacktoTopCom from "../helpers/BackToTopCom";
import FooterHomeTwo from "../partials/Footers/FooterHomeTwo/index";
import HeaderHomeThree from "../partials/Headers/HeaderHomeThree";
import AboutSection from "./AboutSection";
import GetInTouchSection from "./GetInTouchSection";
import Hero from "./Hero";
import ServiceSection from "./ServiceSection";
import SubscribeSection from "./SubscribeSection";
import WorkProcessSection from "./WorkProcessSection";

export default function HomeThree() {
  // const [videoPop, setValue] = useToggle(false);
  return (
    <>
      {/* {videoPop && (
        <VideoPopupCom
          videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
          handler={setValue.toggle}
        />
      )} */}
      <HeaderHomeThree />
      <Hero />
      <ServiceSection />
      <WorkProcessSection />
      <AboutSection />
      {/* <TeamSection teams={teams} /> */}
      {/*<TestimonialSection />*/}
      {/* <PricingSection /> */}
      <SubscribeSection />
      {/*<GallerySection />*/}
      {/*<NewsSection blogs={blogs} className="news-section-three pb-0" /> */}
      <GetInTouchSection />
      <FooterHomeTwo className="footer-home-three pt-150" />
      <BacktoTopCom />
    </>
  );
}
