import React from "react";

export default function AboutSection({ className }) {
  return (
    <section id="CaseStudySection" name="CaseStudySection" className={`about-three py-120 rpy-100 ${className || ""}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-three-image rmb-60 mr-10 rmr-0 wow fadeInLeft delay-0-2s">
              <img
                src={
                  require(`../../assets/images/services/custom_project.png`).default
                }
                alt="About"
              />
              {/* <img
                src={
                  require(`../../assets/images/about/about-three-2.jpg`).default
                }
                alt="About"
              /> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content pl-70 rpl-0 wow fadeInRight delay-0-2s">
              <div className="section-title mb-35">
                <span className="sub-title">Case Study</span>
                <h2>
                  End-to-End Web Solution
                </h2>
              </div>
              <p>
              ThinkAtlas created a custom public-facing system for tracking information about digital currencies in 
              addition to an internal system that would allow the company to respond quickly to customers' changing requirements.
              <br/>A future-proof system was needed in the rapidly evolving space that needed to provide the ability to quickly adapt and embrace new opportunities.
              </p>
              <ul className="list-style-three mt-15">
                <li>Responsive</li>
                <li>Auto-scalable</li>
                <li>Secure</li>
              </ul>
              {/* <a href="/about-one" className="theme-btn style-three mt-25">
                View details
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
