import React from "react";
import useForm from "./useForm";

export default function GetInTouchSection() {

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    sendSuccess,
    isSubmitting,
  } = useForm(sendmessage, validate);

  function sendmessage() {
    console.log('No errors, submit callback called!');
  }

  function validate(values) {
    let errors = {};
    if (!values.name) {
        errors.name = 'Name is required';
      } else if (values.name.length < 2) {
        errors.name = 'Name is invalid';
      }
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email is invalid';
    }
    if (!values.phone) {
      errors.phone = 'Phone number is required';
    } else if (values.phone.length < 8) {
      errors.phone = 'Phone number is invalid';
    }
    if (!values.comment) {
        errors.comment = 'Message is required';
      } else if (values.comment.length < 50) {
        errors.comment = 'Message is invalid';
      }
    return errors;
  };

  return (
    <section id="GetInTouch" name="GetInTouch" className="contact-two">
      <div className="container">
        <div className="contact-section-inner p-50 br-5 bg-white">
          <div className="row">
            <div className="col-lg-7">
              <div className="contact-section-form rmb-50 wow fadeInLeft delay-0-2s">
                <div className="section-title mb-20">
                  <h2>Get In Touch</h2>
                </div>

                {sendSuccess === true ? 
                        <p className="contact-form-success">Message sent successfully!</p> :

                <form onSubmit={handleSubmit} noValidate
                  id="contact-form"
                  className="contact-form"
                  name="contact-form"
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="name">Your name</label>
                        {errors.name && (
                          <p className="contact-form-error">{errors.name}</p>
                        )}
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Name"
                          required
                          onChange={handleChange} 
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="email">Your email address</label>
                        {errors.email && (
                          <p className="contact-form-error">{errors.email}</p>
                        )}                        
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          required
                          onChange={handleChange} 
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="phone">Your phone number</label>
                        {errors.phone && (
                          <p className="contact-form-error">{errors.phone}</p>
                        )}                        
                        <input
                          type="text"
                          id="phone"
                          name="phone"
                          className="form-control"
                          placeholder="Phone Number"
                          required
                          onChange={handleChange} 
                        />
                      </div>
                    </div>
                    {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="service">Service Required</label>
                        <select id="service" name="service">
                          <option value="" selected>
                            Service
                          </option>
                          <option value="">About</option>
                          <option value="">Contact</option>
                          <option value="">Team</option>
                        </select>
                      </div>
                    </div> */}
                    <div className="col-sm-12">
                      <div className="form-group">
                      {errors.comment && (
                          <p className="contact-form-error">{errors.comment}</p>
                        )}                            
                        <textarea
                          name="comment"
                          id="comment"
                          className="form-control"
                          rows="4"
                          placeholder="Let us know what you need."
                          required
                          onChange={handleChange} 
                        ></textarea>
                      </div>
                    </div>


                      <div className="col-sm-12">
                        <button type="submit" className="theme-btn" disabled={isSubmitting}>
                        {isSubmitting ? "Sending..." : "Send Message" }
                        </button>
                      </div>
                    
                  </div>
                </form>

              }




              </div>
            </div>
            <div className="col-lg-5">
              <div
                className="contact-image-number style-two bgs-cover overlay wow fadeInRight delay-0-2s"
                
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Fill out the form and one of our representatives will be in touch with you shortly.</span>
                        {/* <h5>
                          <a href="callto:+012-345-6789">+012-345-6789</a>
                        </h5> */}
                      </div>
                    </li>
                    {/* <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:info@example.com">info@example.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:00 - 7:00</h5>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
